<template>
  <a-card :bordered="false">
    <div class="table-page-search-wrapper">
      <div class="table-page-search-wrapper">
        <a-form layout='inline' @keyup.enter.native='searchQuery'>
          <a-row :gutter="24">
            <a-col :md='8' :sm='8'>
              <a-form-item :label="'产品类型'">
                <a-select mode='multiple'
                          v-model='queryParam.appSsids'
                >
                  <a-select-option v-for='item in appList' :key='item.appssid'>
                    {{ item.appName }}
                  </a-select-option>
                </a-select>

              </a-form-item>
            </a-col>
            <a-col :md="6" :sm="8">
              <a-form-item :label="'产品名称'">
                <a-input v-model="queryParam.productName"></a-input>
              </a-form-item>
            </a-col>
            <a-col :md='6' :sm='8'>
            <span style='float: left;overflow: hidden;' class='table-page-search-submitButtons'>
              <a-button type='primary' @click='searchQuery' icon='search'>{{ $t('search') }}</a-button>
            </span>
            </a-col>
          </a-row>
        </a-form>

      </div>
      <a-row>
        <a-col :md="2" :sm="24">
          <a-button style="margin-bottom: 10px" type="primary" @click="addProduct">新增</a-button>
        </a-col>

      </a-row>
    </div>
    <div class="table-page-search-wrapper">
      <a-table
        ref="table"
        rowKey="id"
        size="middle"
        :columns="columns"
        :dataSource="dataSource"
        :loading="loading"
        bordered>
        <span slot="action" slot-scope="text, record">
          <a @click="handleEdit(record)">
            编辑详情
          </a>
<!--          <a @click="handleEditConfig(record)">-->
<!--            编辑动态字段-->
<!--          </a>-->
          <a @click="updateProduct(record)">
            修改
          </a>
        </span>
      </a-table>
    </div>
    <v-modal
      v-model="visible"
      title="产品详情">
      <div style="padding: 20px;">
        <v-product-item
          v-if="visible"
          :id="productId"
          @close="visible = false;"/>
      </div>
    </v-modal>
    <v-modal
      v-model="visibleConfig"
      title="产品配置详情">
      <div style="padding: 20px;">
        <v-product-fee-config-list
          v-if="visibleConfig"
          :id="productId"
          @close="visibleConfig = false;"/>
      </div>
    </v-modal>
    <product-modal ref="productModal" @ok="modalFormOk" ></product-modal>
  </a-card>
</template>

<script>
import { getProductList } from '@/webpublicapi/operation'
import Dict from '../modules/Dict'
import vModal from '../modules/Modal';
import vProductItem from './ProductItemList.vue'
import vProductFeeConfigList from './ProductFeeConfigList.vue'
import ProductModal from './modules/ProductModal'
import {getApplist} from "@/webpublicapi/order";

export default {
  name: 'ProductManagement',
  components: { Dict, vModal, vProductItem, ProductModal, vProductFeeConfigList },
  data () {
    return {
      visible: false,
      visibleConfig: false,
      dataSource: [],
      loading: false,
      productId: undefined,
      queryParam: {
        'appSsids': '',
        'googleFlag': '2'
      },
      appList: [],
      // 表头
      columns: [
        {
          title: '产品名称',
          align: 'left',
          dataIndex: 'productName',
          customRender: function (text, row, index) {
            return text + "-" + row.appSsid;
          }
        },
        {
          title: '前收后收',
          align: 'left',
          dataIndex: 'receiptTimeFlag',
          customRender: function (text) {
            if (text==='0') {
              return "前收"
            } else {
              return "后收"
            }
          }
        },
         {
          title: '创建时间',
          align: 'left',
          dataIndex: 'createTime'
        },
         {
          title: '更新时间',
          align: 'left',
          dataIndex: 'updateTime'
        },
        {
          title: '操作',
          dataIndex: 'action',
          align: 'center',
          scopedSlots: { customRender: 'action' }
        }
      ]
    }
  },
  created () {
    this.loadData()
    getApplist().then(res => {
      this.appList = res.data || [];
    })
  },
  methods: {
    loadData () {
      this.searchQuery();
    },
    handleEdit (record) {
      this.productId = record.id;
      this.appSsid = record.appSsid;
      this.visible = true;
    },
    handleEditConfig (record) {
      this.productId = record.id;
      this.appSsid = record.appSsid;
      this.visibleConfig = true;
    },
    addProduct() {
      this.$refs.productModal.add({"googleFlag":"2"});
      this.$refs.productModal.title = '新增产品';
    },
    updateProduct(record) {
      record.googleFlag = '2';
      this.$refs.productModal.edit(record);
      this.$refs.productModal.title = '修改产品';
    },
    modalFormOk () {
      this.loadData();
    },
    searchQuery() {
      this.loading = true
      getProductList(Object.assign({}, this.queryParam)).then((res)=>{
        if(res.code===1000){
          this.dataSource = res.data
        }else{
          this.dataSource = []
          this.$message.error(res.msg)
        }
        this.loading = false
      }).catch(e => {
        this.loading = false;
        this.$message.error(e.message);
      })
    },
  }
}
</script>
<style lang="less" scoped>
 @import "~@/assets/less/common.less";
</style>
