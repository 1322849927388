import { render, staticRenderFns } from "./TestOrderList.vue?vue&type=template&id=a3e296ce&scoped=true&"
import script from "./TestOrderList.vue?vue&type=script&lang=js&"
export * from "./TestOrderList.vue?vue&type=script&lang=js&"
import style0 from "./TestOrderList.vue?vue&type=style&index=0&id=a3e296ce&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "a3e296ce",
  null
  
)

export default component.exports